<template>
  <v-container fluid class="px-lg-5">
    <div class="col-12">
      <v-card class="rounded-card" flat outlined>
        <v-data-table :headers="headers" :items="permissions" item-key="id" :options.sync="options"
          @update:options="updateOptions" :server-items-length="totalCount" :loading="loading"
          :no-data-text="$t('messages.emptyState', { entity: $tc('models.permission.entity') })"
          :no-results-text="$t('messages.emptyState', { entity: $tc('models.permission.entity') })">
          <template v-slot:[`item.title`]="{ item }">
            <div class="py-1">
              <div>{{ $t(`availablePermissions.${availablePermissions[item.sym]}.title`) }}</div>
              <small class="text-muted">
                {{ $t(`availablePermissions.${availablePermissions[item.sym]}.description`) }}
              </small>
              <app-field v-if="item.enabled"
                v-bind="item.field" @input="bindData($event, item.field)"
                :class="{ 'readonly-chips': !isAdmin }" />
            </div>
          </template>
          <template v-slot:[`item.enabled`]="{ item }">
            <v-simple-checkbox v-model="item.enabled" :disabled="!isAdmin"></v-simple-checkbox>
          </template>
        </v-data-table>
        <v-btn v-if="isAdmin" depressed color="primary" class="pull-right my-3" @click="submit">
          {{ $t('actions.save') }}
        </v-btn>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {treeSelectOptions, availablePermissions} from 'constants';

export default {
  data() {
    return {
      permissions: [],
      availablePermissions: availablePermissions,
      totalCount: 0,
      headers: [{
        text: this.$t('views.users.permission'),
        sortable: false,
        value: 'title'
      },
      {
        text: '',
        align: 'end',
        sortable: false,
        value: 'enabled'
      }],
      loading: true,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['permission_sym'],
        sortDesc: [false],
        mustSort: ['true'],
        multiSort: false
      }
    };
  },
  computed: mapGetters(['userPermissions', 'isAdmin', 'departments']),
  methods: {
    ...mapActions(['getUserPermissions', 'updateUserPermissions', 'getDepartments']),
    bindData(userIds, field) {
      this.$set(field, 'value', userIds);
    },
    updateOptions(options) {
      this.loading = true;
      Promise.all([
        this.getDepartments({fields: ['name', 'users']}),
        this.getUserPermissions({uuid: this.$route.params.uuid, options: options})
      ]).then(() => {
        this.totalCount = this.userPermissions.count;
        this.permissions = this.userPermissions.items.map((permission) => {
          return {
            ...permission,
            field: {
              type: 'treeSelect',
              key: 'subjectUsers',
              value: permission.subjectUsers.map((user) => user.id),
              options: this.departments.items.map((department) => ({
                id: `dep-${department.id}`,
                label: department.name,
                isDisabled: !department.users.length,
                children: department.users.map((user) => ({
                  id: +user.id,
                  label: user.name
                }))
              })),
              valueConsistsOf: treeSelectOptions.LEAF_PRIORITY,
              multiple: true,
              chips: true,
              disabled: !this.isAdmin,
              itemText: 'name',
              itemValue: 'id'
            }
          };
        });
        this.loading = false;
      });
    },
    submit() {
      const data = new FormData();
      let index = 0;
      this.permissions.forEach((permission) => {
        if (permission.field.value.length > 0) {
          permission.field.value.forEach((userId) => {
            data.append(`superior_user_permissions_attributes[${index}][permission_id]`, permission.sym);
            data.append(`superior_user_permissions_attributes[${index}][subject_user_id]`, userId);
            index++;
          });
        }
      });
      this.updateUserPermissions({userId: this.$route.params.uuid, permissions: data});
    }
  }
};
</script>

<style lang="scss">
.readonly-chips {
  .v-text-field__details,
  .v-input__icon.v-input__icon--append {
    display: none !important;
  }
  .v-text-field {
    padding-top: 0;
    margin-top: 0;
  }
  .v-input__slot {
    margin-bottom: 0;
    padding: 0 !important;
    &:before {
      display: none !important;
    }
  }
}

.v-data-table__mobile-row__header {
  display: none;
}

.v-data-table__mobile-row__cell {
  text-align: left !important;
}
</style>
